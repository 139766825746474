type Setting = {
  id: number;
  companyLogo: string;
  companyLogoB64?: string;
};

export const defaultSetting = {
  id: 1,
  companyLogo: '',
  companyLogoB64: '',
};

export type { Setting };
