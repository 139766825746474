import RequestService from './request';

export const updateCompanyLogo = async (file: File) => {
  let req = new RequestService();
  const formData = new FormData();
  formData.append('logo', file);
  return await req.fetchEndpoint(
    `setting/company-logo`,
    'POST',
    formData,
    false
  );
};
// axios interceptors
export const getSetting = async () => {
  const req = new RequestService();
  return await req.fetchEndpoint(`setting`, 'GET', {}, false);
};

export const getCompanyLogo = async () => {
  const req = new RequestService();
  return await req.fetchEndpoint(`setting/company-logo`, 'GET', {}, false);
};
